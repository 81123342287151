/* eslint-disable*/
import $ from "jquery";
var gee = gee || $.fn.gene;
var app = window.App;

export default {
    name: "member",
    current: {},

    init: function() {
        this.hook();
    },

    boot: function () {
        this.chkLogin();
    },

    chkLogin: function() {
        var callback = function() {
            if (!this.code || this.code !== '1') {;
                gee.clog('Did not login');
                app.body.removeClass('login').addClass('logout');

                if (gee.isset(this.data) && gee.isset(this.data.msg)) {
                    gee.alert({
                        title: 'Alert!',
                        txt: this.data.msg
                    });
                }

                if (gee.isset(this.data) && gee.isset(this.data.uri)) {
                    location.href = (this.data.uri === '') ? gee.apiUri : this.data.uri;
                }
            }
            else {
                gee.clog('Logined');
                app.body.removeClass('logout').addClass('login');
                app.member.current = this.data
            }
        };

        gee.yell('/member/chk_login', {}, callback, callback);
    },

    login: function(data) {
        var callback = function() {
            if (!this.code || this.code !== '1') {
                app.stdErr(this);

                if (gee.isset(this.data.uri)) {
                    location.href = (this.data.uri === '') ? gee.apiUri : this.data.uri;
                }
            }
            else {
                // app.body.removeClass('logout').addClass('login');

                if (gee.isset(this.data.msg)) {
                    gee.alert({
                        title: 'Alert!',
                        txt: this.data.msg
                    });
                }

                if (gee.isset(this.data.uri)) {
                    location.href = (this.data.uri === '') ? gee.apiUri : this.data.uri;
                }
            }
        };

        gee.yell('/member/login', data, callback, callback);
    },

    register: function(data, btn) {
        var callback = function() {
            btn.removeAttr('disabled').find('i').remove();

            if (this.code == '1') {

                if (gee.isset(this.data.msg)) {
                    gee.alert({
                        title: 'Alert!',
                        txt: this.data.msg
                    });
                }

                if (gee.isset(this.data.uri)) {
                    location.href = (this.data.uri === '') ? gee.apiUri : this.data.uri;
                }

                if (gee.isset(this.data.goback)) {
                    history.go(-1);
                }
            } else {
                if (gee.isset(this.data) && gee.isset(this.data.msg)) {
                    gee.alert({
                        title: 'Alert!',
                        txt: this.data.msg
                    });
                } else {
                    gee.alert({
                        title: 'Error!',
                        txt: 'Server Error, Plaese Try Later(' + this.code + ')'
                    });
                }
            }
        };

        gee.yell('/member/add_new', data, callback, callback);
    },

    update: function(data, btn) {
        var callback = function() {
            btn.removeAttr('disabled').find('i').remove();

            if (this.code == '1') {

                if (gee.isset(this.data.msg)) {
                    gee.alert({
                        title: 'Alert!',
                        txt: this.data.msg
                    });
                }

                if (gee.isset(this.data.uri)) {
                    location.href = (this.data.uri === '') ? gee.apiUri : this.data.uri;
                }

                if (gee.isset(this.data.goback)) {
                    history.go(-1);
                }
            } else {
                if (gee.isset(this.data) && gee.isset(this.data.msg)) {
                    gee.alert({
                        title: 'Alert!',
                        txt: this.data.msg
                    });
                } else {
                    gee.alert({
                        title: 'Error!',
                        txt: 'Server Error, Plaese Try Later(' + this.code + ')'
                    });
                }
            }
        };

        gee.yell('/member/update', data, callback, callback);
    },

    check: function(account, laborid, col) {
        var callback = function() {
            // col.removeAttr('disabled').find('i').remove();

            if (!this.code || this.code !== '1') {
                app.stdErr(this);
            }
            else {
                if (this.data.existed) {
                    gee.alert({
                        title: 'Alert!',
                        txt: '帳號及勞保證號已存在'+ "\n\r" +'請確認是否正確或使用忘記密碼。'
                    });
                }
            }
        };

        gee.yell('/member/check', {account: account, laborid: laborid}, callback, callback);
    },

    logout: function() {
        var callback = function() {
            if (!this.code || this.code !== '1') {
                app.stdErr(this);
            }
            else {
                location.href = '/';
            }
        };

        gee.yell('/member/logout', {}, callback, callback, 'GET');
    },

    hook: function() {
        gee.hook('login', function(me){
            var f = me.data('ta') ? $('#' + me.data('ta')) : me.closest('form');

            if (!gee.formValidate(f)) {
                return false;
            }
            else {
                return app.member.login(f.serialize());
            }
        });

        gee.hook('register', function(me){
            var form = me.data('ta') ? $('#' + me.data('ta')) : me.closest('form');

            form.find('input').each(function() {
                if ($(this).val() == $(this).attr('placeholder')) $(this).val('');
            });

            if (form.find('input[name="agree"]:checked').length) {
                if (!gee.formValidate(form)) {
                    return false;
                }
                else {
                    var chk = 1;
                    var txt = [];

                    if ($('#pwd').isPasswdErr()) {
                        txt.push('密碼：請確認是否符合 8~16 字英文及數字');
                        chk = 0;
                    }

                    if ($('#cpwd').val() !== $('#pwd').val()) {
                        txt.push('密碼與確認密碼不相同');
                        chk = 0;
                    }

                    if (chk === 1) {
                        me.attr('disabled', 'disabled').append('<i class="fa fa-spinner"></i>');
                        return app.member.register(form.serialize(), me);
                    }
                    else {
                        gee.alert({
                            title: 'Error!',
                            txt: txt.join("\r\n")
                        });
                    }
                }
            }
            else {
                gee.alert({
                    title: 'Error!',
                    txt: '您尚未同意會員條款'
                });
            }
        });

        gee.hook('chkExist', function(me){
            var form = me.data('ta') ? $('#' + me.data('ta')) : me.closest('form');

            var account = form.find('input[name="account"]').val();
            var laborid = form.find('input[name="laborid"]').val();

            if (account && laborid) {
                var erp = /^(?=.*\d)(?=.*[a-zA-Z]){2,}(?=.*[a-zA-Z])(?!.*\s).{8,16}$/;

                if (erp.test(account) !== true) {
                    gee.alert({
                        title: 'Error!',
                        txt: '帳號請確認是否符合 8~16 字英文及數字'
                    });
                }
                else {
                    return app.member.check(account, laborid, me);
                }
            }
            else {
                gee.alert({
                    title: 'Error!',
                    txt: '帳號及勞保證號不能為空'
                });
            }
        });

        gee.hook('modify', function(me){
            var form = me.data('ta') ? $('#' + me.data('ta')) : me.closest('form');

            form.find('input').each(function() {
                if ($(this).val() == $(this).attr('placeholder')) $(this).val('');
            });

            if (!gee.formValidate(form)) {
                return false;
            }
            else {
                var chk = 1;
                var txt = [];

                if ($('#pwd').val()) {
                    if ($('#pwd').isPasswdErr()) {
                        txt.push('密碼：請確認是否符合 8~16 字英文及數字');
                        chk = 0;
                    }

                    if ($('#cpwd').val() !== $('#pwd').val()) {
                        txt.push('密碼與確認密碼不相同');
                        chk = 0;
                    }
                }

                if (chk === 1) {
                    me.attr('disabled', 'disabled').append('<i class="fa fa-spinner"></i>');
                    return app.member.update(form.serialize(), me);
                }
                else {
                    gee.alert({
                        title: 'Error!',
                        txt: txt.join("\r\n")
                    });
                }
            }
        });

        gee.hook('restPsw', function(me){
            var form = me.data('ta') ? $('#' + me.data('ta')) : me.closest('form');

            form.find('input').each(function() {
                if ($(this).val() == $(this).attr('placeholder')) $(this).val('');
            });

            if (!gee.formValidate(form)) {
                return false;
            }
            else {
                var chk = 1;
                var txt = [];

                if ($('#pwd').val()) {
                    if ($('#pwd').isPasswdErr()) {
                        txt.push('密碼：請確認是否符合 8~16 字英文及數字');
                        chk = 0;
                    }

                    if ($('#cpwd').val() !== $('#pwd').val()) {
                        txt.push('密碼與確認密碼不相同');
                        chk = 0;
                    }
                }

                if (chk === 1) {
                    return gee.exe('stdSubmit', me);
                }
                else {
                    gee.alert({
                        title: 'Error!',
                        txt: txt.join("\r\n")
                    });
                }
            }
        });

        gee.hook('logout', function(me){
            app.member.logout();
        });
    }

};
