import $ from 'jquery';
import Cookies from 'js-cookie';
var gee = gee || $.fn.gene;
var app = window.App;

export default {
    name: 'report',
    current: {},

    init: function() {
        this.hook();
    },

    boot: function () {
        // run after app.init()
    },

    del: function(pid, row) {
        var callback = function() {
            if (!this.code || this.code !== '1') {
                app.stdErr(this);
            }
            else {
                if (gee.isset(this.data.msg)) {
                    gee.alert({
                        title: 'Alert!',
                        txt: this.data.msg
                    });
                }

                row.remove();
            }
        };

        gee.yell('/report/del_this', {pid: pid}, callback, callback);
    },

    hook: function () {
        gee.hook('showAddFileModal', function(me) {
            var pid = me.data('pid');
            $('#report_id').val(pid);
            // $('#new_report').modal('show');
            app.modal.toggle({ta: '.modal-upload-report', size: 'nor'});
        });

        gee.hook('removeReport', function(me) {
            if (confirm('確定刪除此計畫？')) {
                var pid = me.data('pid');

                if (gee.isset(pid)) {
                    app.report.del(pid, me.closest('tr'));
                }
            }
        });

        gee.hook('largerFont', function() {
        	var cufontSize = app.fontSize * 1 + 0.1;
        	app.setSzie(cufontSize);
          Cookies.set('fontSize', cufontSize, { expires: 7 });
        });

        gee.hook('smallerFont', function() {
        	var cufontSize = app.fontSize * 1 - 0.1;
        	app.setSzie(cufontSize);
          Cookies.set('fontSize', cufontSize, { expires: 7 });
        });
    }

};


