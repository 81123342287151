/**
 * app
 */

import Cookies from 'js-cookie';

var gee = window.gee || $.fn.gene;
window.gee = gee;

var App = function() {
	"use strict";

	var that = this;

	that.config = {
		detectWidth: 600
	};

	var app = {
		// pageCounter: 1,
		// pageLimit: 10,

		fontSize: 1,

		redo: null,

		module: {},

		tmplStores: {},
		htmlStores: {},
		tmplPath: "./app/tmpls",
		use: function(mod) {
			if (gee.isset(mod) && gee.isset(mod.name)) {
				app[mod.name] = mod;
			} else {
				console.log("mod 需要命名");
			}
			if (gee.isset(mod.init)) {
				app[mod.name].init();
				// console.log(mod.name + " init!!!");
			}
		},
		init: function(modules) {
			app.win = $(window);
			app.docu = $(document);
			app.body = app.win.opera
				? app.docu.compatMode === "CSS1Compat"
					? $("html")
					: $("body")
				: $("body");

			app.screen =
				app.body.width() < that.config.detectWidth ? "mobile" : "tablet";
			app.body.addClass(app.screen);

			if (app.body.height() < app.win.height()) {
				var h =
					app.win.height() -
					$(".app-header").outerHeight() -
					$(".app-footer").outerHeight() -
					$(".navbar").outerHeight() -
					$(".necker").outerHeight();
				$(".bodier").css("height", h + "px");
			}

			gee.apiUri = window.apiUrl + "";
			gee.mainUri = window.mainUrl;

			gee.init();

			if (modules && modules.length > 0) {
				modules.map(function(module) {
					if (gee.isset(app[module]) && gee.isset(app[module].boot)) {
						app[module].boot();
					}
				});
			}

            app.setSzie(( Cookies.get('fontSize') == undefined ? app.fontSize : Cookies.get('fontSize')*1 ));
		},

		setSzie: function function_name(cufontSize) {
			app.fontSize = cufontSize;
			$('.article .wysiwyg').css('fontSize', cufontSize + 'rem');
		},

		stdErr: function(e, redo) {
			if (e.code === '100') {
				app.redo = redo || null;
				app.body.removeClass('login').addClass('logout');

				gee.alert({
					title: 'Alert!',
					txt: '請重新登入'
				});
			}
			else {
				if (gee.isset(e.data.msg)) {
					gee.alert({
						title: 'Alert!',
						txt: e.data.msg
					});
				} else {
					gee.alert({
						title: 'Error!',
						txt: 'Server Error, Plaese Try Later(' + e.code + ')'
					});
				}
			}
		},
	};

	return app;
};

gee.stdSubmit = function(me) {
	var g = gee,
		f = me.data('ta') ? $('#' + me.data('ta')) : me.closest('form'),
		dAction = function() {

			me.removeAttr('disabled').find('i').remove();

			if (this.code == '1') {
				if (me.attr('reset') === '1') {
					f[0].reset();
				}

				if (gee.isset(this.data.msg)) {
					gee.alert({
						title: 'Alert!',
						txt: this.data.msg
					});
				}

				if (gee.isset(this.data.uri)) {
					location.href = (this.data.uri === '') ? g.apiUri : this.data.uri;
				}

				if (gee.isset(this.data.goback)) {
					history.go(-1);
				}

				if (gee.isset(this.data.reset)) {
					f[0].reset();
				}

				if (gee.check(this.data.func)) {
					gee.clog(this.data.func);
					gee.exe(this.data.func, me);
				}
			} else {
				if (gee.isset(this.data) && gee.isset(this.data.msg)) {
					gee.alert({
						title: 'Alert!',
						txt: this.data.msg
					});
				} else {
					g.alert({
						title: 'Error!',
						txt: 'Server Error, Plaese Try Later(' + this.code + ')'
					});
				}
			}
		};

	f.find('input').each(function() {
		if ($(this).val() == $(this).attr('placeholder')) $(this).val('');
	});

	if (!g.formValidate(f)) {
		return false;
	}
	else {
		me.attr('disabled', 'disabled').append('<i class="fa fa-spinner"></i>');

		g.yell(me.data('uri'), f.serialize(), dAction, dAction);
	}

};

gee.formValidate = function(me) {
	var g = gee,
		chk = 1;

	me.find('input[require], select[require], textarea[require]').each(function() {

		if (chk === 0) {
			return chk;
		}

		var $cu = $(this);
		var label = $cu.attr('title') || $cu.attr('name');
		var txt = [];

		if ($cu.is(':visible')) {

			if ($cu.isEmpty()) {
				txt.push('請輸入' + label);
				chk = 0;
			}

			g.clog('required:' + $cu.attr('require'));

			if ($cu.attr('require') == 'password') {
				if ($cu.isPasswdErr()) {
					txt.push(label + '：請確認是否符合 8~16 字英文及數字');
					chk = 0;
				}
			}

			if ($cu.attr('require') == 'sameWith') {
				var $ta = $('#' + $cu.data('ta'));
				if ($cu.val() != $ta.val()) {
					txt.push(label + '不符合' + $ta.attr('title'));
					chk = 0;
				}
			}

			if ($cu.attr('require') == 'email') {
				if ($cu.isEmailErr()) {
					txt.push(label + '：請確認是否符合 Email 格式');
					chk = 0;
				}
			}

			if ($cu.attr('require') == 'chinese') {
				if ($cu.isChineseErr()) {
					txt.push(label + '：請確認是否為全中文');
					chk = 0;
				}
			}

			if ($cu.attr('require') == 'number') {
				if ($cu.isNumberErr()) {
					txt.push(label + '：請確認是否符合數字格式');
					chk = 0;
				}

				if ($cu.data('min') && chk == 1) {
					if ($cu.data('min') > $cu.val()) {
						txt.push('' + label + '：應大於 ' + $cu.data('min'));
						chk = 0;
					}
				}

				if ($cu.data('max') && chk == 1) {
					if ($cu.data('max') > $cu.val()) {
						txt.push('' + label + '：應小於 ' + $cu.data('max'));
						chk = 0;
					}
				}
			}

		}

		if (txt.length > 0) {
			g.alert({
				title: 'Error!',
				txt: txt.join("/r/n")
			});
		}
	});

	return chk;
};

gee.yell = function(uri, postData, successCB, errorCB, type, hideLoadAnim) {

	var g = gee;
	var json = (uri.indexOf('://') == -1) ? "json" : "jsonp";
	var headers = {};
	type = type || 'POST';
	uri = (g.apiUri !== '') ? g.apiUri + uri : uri;

	if (!hideLoadAnim) {
		g.loadAnim('show');
	}

	if (g.isset(window.csrf_token)) {
		headers['X-Requested-Token'] = window.csrf_token;
	}

	$.ajax({
			'url': uri,
			"type": type,
			"data": postData,
			"dataType": json,
			'cache': false,
			'headers': headers
		})
		.done(function(j) {
			if (j) {
				g.clog(j);
				if (g.isset(j.csrf)) {
					window.csrf_token = j.csrf;
					g.clog(window.csrf_token);
				}
				if (g.isset(j.code) && j.code !== '0') {
					if (typeof errorCB == 'function') {
						errorCB.call(j);
					}
					else {
						g.alert({
							title: 'Error!',
							txt: 'Server Error, Plaese Try Later(' + j.code + ')'
						});
					}
				}
				else {
					if (typeof successCB == 'function') {
						successCB.call(j);
					}
				}
			} else {
				g.alert({
					title: 'Error!',
					txt: 'Server Error, Plaese Try Later(2)'
				});
			}
		})
		.fail(function(o, s) {
			g.err('ajax fail(' + o.status + ')!!');
		})
		.always(function() {
			g.clog('ajax complete');
			if (!hideLoadAnim) {
				g.loadAnim('hide');
			}
		});
};



window.App = new App(); /* eslint-disable */
