/* eslint-disable*/
import $ from "jquery";
var gee = gee || $.fn.gene;
var app = window.App;

export default {
    name: "upload",
    current: {},

    init: function() {
        this.hook();
    },

    showFileName: function(file, ta) {
        var reader = new FileReader();

        reader.onload = function(e) {
            $('#'+ ta).text(file.name);
        };

        reader.readAsDataURL(file);
    },

    progress: function(data, fileInput, uri) {
        var callback = function(rtn) {
            if (!rtn.code || rtn.code !== '1') {
                app.stdErr(rtn);
            }
            else {
                if (gee.isset(rtn.data.msg)) {
                    gee.alert({
                        title: 'Alert!',
                        txt: rtn.data.msg
                    });
                }
            }
        };

        var fd = new FormData();
        fd.append('file', fileInput[0].files[0]);

        $.each(data, function(idx, row){
            fd.append(row.name, row.value);
        });

        $.ajax({
            url: gee.apiUri + uri,
            data: fd,
            processData: false,
            contentType: false,
            type: 'POST',
            success: callback
        });
    },

    hook: function () {

        gee.hook('passFile', function(me){
            var file = me[0].files[0];
            var ta = me.data('ta');

            app.upload.showFileName(file, ta);
        });

        gee.hook('upload', function(me){
            var form = me.data('ta') ? $('#' + me.data('ta')) : me.closest('form');
            var fileInput = form.find('input:file');
            var uri = me.data('uri');

            if (fileInput.val() === '') {
                return false;
            }
            else {
                app.upload.progress(form.serializeArray(), fileInput, uri);
            }
        });
    }

};
